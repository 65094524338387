import React, { useContext } from "react";
import { FaHome } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import {
  PiTelevisionSimpleThin,
  PiTableThin,
  PiClockThin,
} from "react-icons/pi";
import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IoIosStats } from "react-icons/io";
import { GiRugbyConversion } from "react-icons/gi";
import { GoCalendar, GoFile } from "react-icons/go";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { ControlContext } from "../../contexts/ControlContext";
import { HiOutlinePencil } from "react-icons/hi";
import { BsGraphUp } from "react-icons/bs";

const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 0.5em;
  font-size: 1.5em;
  flex-direction: column;
  height: 100vh;
`;

const BottomIcons = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 10px;
  align-items: center;
  flex-direction: column;
`;

const SettingsIcon = styled(FiSettings)`
  cursor: pointer;
`;

const LogIcon = styled(GoFile)`
  cursor: pointer;
`;
const Icon = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.selected === true ? "#db0a41" : "")};
  width: 100%;
  display: flex;
  justify-content: center;
  height: ${(props) => (props.home ? "92px" : "50px")};
  align-items: center;
  border-bottom: ${(props) => (props.home ? "1px solid #3d485d" : "")};
  box-shadow: ${(props) =>
    props.home ? "1px 5px 4px 1px rgb(0 0 0 / 25%)" : ""};
   color: ${(props) => (props?.selected ? "#db0a41" : "")}}
`;

export default function LeftSideBar({ view, setShowConfig, isFruit = false }) {
  const history = useHistory();
  const location = useLocation();
  let { gameID } = useParams();
  const { engines } = useContext(ControlContext);
  return (
    <Main
      style={
        (view === VIEW_CONSTS.matchSelect && {
          height: "calc(100vh - 50px)",
        }) ||
        {}
      }
    >
      {!isFruit &&
        view !== VIEW_CONSTS.matchSelect &&
        view !== VIEW_CONSTS.tackle && (
          <Icon
            home
            onClick={() => {
              history.push("/" + gameID);
            }}
          >
            <FaHome />
          </Icon>
        )}
      {!isFruit && view === VIEW_CONSTS.tackle && (
        <Icon
          home
          onClick={() => {
            history.push("/tackles/" + gameID);
          }}
        >
          <FaHome />
        </Icon>
      )}
      {!isFruit && view === VIEW_CONSTS.tackle && (
        <Icon
          onClick={() => {
            history.push("/tackles/" + gameID + "/edit");
          }}
        >
          <HiOutlinePencil />
        </Icon>
      )}
      {!isFruit &&
        view !== VIEW_CONSTS.matchSelect &&
        view !== VIEW_CONSTS.tackle && (
          <Icon
            selected={view === VIEW_CONSTS.clockEdit}
            onClick={() => {
              history.push("/" + gameID + "/clock");
            }}
          >
            <PiClockThin />
          </Icon>
        )}
      {!isFruit &&
        view !== VIEW_CONSTS.matchSelect &&
        view !== VIEW_CONSTS.tackle &&
        (window.ENV?.REACT_APP_SKY === "true" ||
          process.env.REACT_APP_SKY === "true") && (
          <Icon
            selected={view === VIEW_CONSTS.bigScreen}
            onClick={() => {
              history.push("/" + gameID + "/bigscreen");
            }}
          >
            <PiTelevisionSimpleThin />
          </Icon>
        )}

      {!isFruit &&
        view !== VIEW_CONSTS.matchSelect &&
        view !== VIEW_CONSTS.tackle && (
          <Icon
            selected={view === VIEW_CONSTS.scores}
            onClick={() => {
              history.push("/" + gameID + "/scores");
            }}
          >
            <GiRugbyConversion />
          </Icon>
        )}

      {!isFruit &&
        view !== VIEW_CONSTS.matchSelect &&
        view !== VIEW_CONSTS.tackle &&
        (window.ENV?.REACT_APP_SKY === "true" ||
          process.env.REACT_APP_SKY === "true") && (
          <Icon
            selected={view === VIEW_CONSTS.table}
            onClick={() => {
              history.push("/" + gameID + "/table");
            }}
          >
            <PiTableThin />
          </Icon>
        )}

      {!isFruit &&
        view !== VIEW_CONSTS.matchSelect &&
        view !== VIEW_CONSTS.tackle &&
        (window.ENV?.REACT_APP_SKY === "true" ||
          process.env.REACT_APP_SKY === "true") && (
          <Icon
            selected={view === VIEW_CONSTS.scores}
            onClick={() => {
              history.push("/" + gameID + "/kick_predictor");
            }}
          >
            K
          </Icon>
        )}

      {/* {!isFruit &&
        view !== VIEW_CONSTS.matchSelect &&
        view !== VIEW_CONSTS.tackle &&
        (window.ENV?.REACT_APP_SKY === "true" ||
          process.env.REACT_APP_SKY === "true") && (
          <Icon
            selected={view === VIEW_CONSTS.scores}
            onClick={() => {
              history.push("/" + gameID + "/season_rankings");
            }}
          >
            <BsGraphUp />
          </Icon>
        )} */}

      {view !== VIEW_CONSTS.matchSelect && (
        <BottomIcons
          style={{ marginBottom: window.hideHeader ? "50px" : "20px" }}
        >
          <Icon
            onClick={() => {
              if (location?.pathname?.indexOf("tackle") > -1) {
                history.push({
                  pathname: "/tackles",
                });
              } else if (location?.pathname?.indexOf("sky_fruit") > -1) {
                history.push({
                  pathname: "/sky_fruit",
                });
              } else {
                history.push({
                  pathname: "/",
                });
              }
            }}
          >
            <GoCalendar />
          </Icon>
          {!isFruit && (
            <Icon>
              <SettingsIcon onClick={() => setShowConfig(true)} />
            </Icon>
          )}
        </BottomIcons>
      )}

      {view === VIEW_CONSTS.matchSelect && (
        <BottomIcons
          style={{ marginBottom: window.hideHeader ? "50px" : "20px" }}
        >
          <Icon>
            <SettingsIcon onClick={() => setShowConfig(true)} />
          </Icon>
        </BottomIcons>
      )}
    </Main>
  );
}
