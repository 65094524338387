import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { DataContext } from "../../contexts/DataContext";
import { FiPlay } from "react-icons/fi";
import { AiOutlinePause } from "react-icons/ai";
import { Button } from "../../Theme/Hyper";
import { APIContext } from "../../contexts/APIContext";
import { ClockContext } from "../../contexts/ClockContext";
import { useHistory, useLocation } from "react-router";
import { ControlContext } from "../../contexts/ControlContext";
import useLocalStorage from "../../Hooks/useLocalStorage";

const Main = styled.div`
  display: flex;
  font-weight: 100;
  box-shadow: 0px 5px 3px 0 rgb(0 0 0 / 12%);
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  z-index: 0;
  border-bottom: 1px solid #3d485d;
`;
const ClockSection = styled.div`
  display: flex;
  min-width: 70px;
  height: 90px;
  justify-content: ${(props) => (props.mins ? "flex-end" : "flex-start")};
`;
const ClockSplit = styled.div`
  height: 90px;
`;
const Half = styled.div`
  display: flex;
  font-size: 1.5em;
`;

const ClockContainer = styled.div`
  display: flex;
  font-size: 4em;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
`;
const ClockButton = styled.div`
  font-size: 2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 100%;
`;
const AddedTime = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
`;
const AddedTimeButton = styled.div`
  font-size: 1em;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#181d25")};

  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 25%;
  box-sizing: border-box;
  :hover {
    background-color: #db0a41;
  }
`;
const PauseResumeButton = styled(Button)`
  font-size: 0.3em;
  padding: 0.5em;
  font-weight: 100;
  margin-left: 0.5em;
  margin-right: 0.5em;
  height: 30px;
`;
const flashAnimation = keyframes`
0% { background-color: '' }
50% { background-color: red}
0% { background-color: '' 
`;
const ClockDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  animation-name: ${(props) => (props.flash ? flashAnimation : "")};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ClockTypeButton = styled.div`
  font-size: 14px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#181d25")};
  border-radius: 4px;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-left: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;
let stadium_clock = "";
export default function Clock({
  game,
  teams,
  previewGraphic,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
}) {
  const history = useHistory();
  const location = useLocation();
  const { updateGame } = useContext(APIContext);
  const {
    auto_clock,
    clockType,
    setClockType,
    setPeriod,
    wsStatus: clockStatus,
  } = useContext(ClockContext);
  const { update } = useContext(ControlContext);
  const [clock, setClock] = useState();
  const [homeSubsMade, setHomeSubsMade] = useLocalStorage(
    game?._id + "_homeSubsMade",
    0
  );
  const [awaySubsMade, setAwaySubsMade] = useLocalStorage(
    game?._id + "_awaySubsMade",
    0
  );

  const [injuryTime, setInjuryTime] = useState(10);
  const [minutes, setMinutes] = useState();
  const [countDown, setCountDown] = useState(true);

  useEffect(() => {
    if (game) {
      if (
        game?.competition?.name === "Challenge Cup" ||
        game?.competition?.name === "Champions Cup" ||
        game?.competition?.name === "URC" ||
        game?.competition?.name === "Autumn Internationals" ||
        window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true"
      ) {
        setCountDown(false);
      }
    }
  }, [game]);

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     try {
  //       window.control_ws.send(
  //         JSON.stringify({
  //           action: "update",
  //           group: window.engines?.[0]?.key,
  //           engine: window.engines?.[0]?.key,
  //           data: {
  //             scene: "U3_RugbyCLOCK",
  //             data: [
  //               {
  //                 name: "Stadium_Clock",
  //                 value: {
  //                   text: stadium_clock,
  //                 },
  //               },
  //             ],
  //           },
  //         })
  //       );
  //     } catch (err) {
  //       debugger;
  //     }
  //   }, 500);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  useEffect(() => {
    stadium_clock = auto_clock;
  }, [auto_clock]);

  let period = useMemo(() => {
    let p = [...(game?.live_data?.periods || [])].sort(
      (a, b) => b.number - a.number
    )[0];
    setPeriod(p);
    return p;
  }, [game]);

  const pause = useCallback(() => {
    let new_period = { ...JSON.parse(JSON.stringify(period)) };
    if (!new_period.pause) {
      new_period.pause = [];
    }

    let newData = { ...JSON.parse(JSON.stringify(game)) };
    newData.live_data.periods = [
      {
        kick_off: new_period.kick_off,
        number: new_period.number,
        pause: [...new_period.pause, { start: moment().valueOf() }],
      },
    ];
    updateGraphic("CLOCK", {
      type: "PAUSE",
      game: newData,
      period: new_period,
    });
    updateGame({ id: game._id, live_data: newData.live_data });
  }, [game, period, updateGame, updateGraphic]);

  const resume = useCallback(() => {
    let new_period = { ...JSON.parse(JSON.stringify(period)) };
    if (!new_period.pause) {
      new_period.pause = [];
    }

    if (!new_period.pause[new_period.pause.length - 1].end) {
      new_period.pause[new_period.pause.length - 1].end = moment().valueOf();
    }

    let newData = { ...JSON.parse(JSON.stringify(game)) };
    newData.live_data.periods = [
      {
        kick_off: new_period.kick_off,
        number: new_period.number,
        pause: [...new_period.pause],
      },
    ];

    updateGraphic("CLOCK", {
      type: "RESUME",
      game: newData,
      period: new_period,
    });
    updateGame({ id: game._id, live_data: newData.live_data });
  }, [game, period, updateGame, updateGraphic]);

  const updateClock = useCallback(
    (koTime, period) => {
      let clockTime = "00:00";
      let rugby_type = game?.competition?.competition_type;
      if (koTime) {
        let pauseTime = 0;

        period.pause &&
          period.pause.forEach((pause) => {
            if (pause.end) {
              pauseTime += pause.end - pause.start;
            }
          });
        let latestPause = period.pause && period.pause[period.pause.length - 1];
        if (latestPause && latestPause.end) {
          latestPause = null;
        }
        let time = moment.duration(
          ((latestPause && latestPause.start) || Date.now()) -
            koTime -
            pauseTime
        );

        let minutes = time.minutes() + time.hours() * 60;

        let cd_mins = 39;
        let cd_secs = 59;
        if (period.number === 1) {
          if (minutes >= 40 && rugby_type === "league") {
            minutes = 40;
            cd_mins = 40;
            time = moment.duration(0);
            cd_secs = time;
          }
        } else if (period.number === 2) {
          minutes += 40;
          cd_mins = 79;
          if (minutes >= 80 && rugby_type === "league") {
            minutes = 80;
            cd_mins = 80;
            time = moment.duration(0);
            cd_secs = time;
          }
        } else if (period.number === 3) {
          minutes += 0;
          cd_mins = 4;
          if (minutes >= 5 && rugby_type === "league") {
            minutes = 5;
            cd_mins = 5;
            time = moment.duration(0);
            cd_secs = time;
          }
        } else if (period.number === 4) {
          minutes += 5;
          cd_mins = 9;
          if (minutes >= 10 && rugby_type === "league") {
            minutes = 10;
            cd_mins = 10;
            time = moment.duration(0);
            cd_secs = time;
          }
        }

        setMinutes(minutes);
        if (countDown) {
          clockTime =
            (cd_mins - minutes).toString().padStart(2, "0") +
            ":" +
            (cd_secs - time.seconds()).toString().padStart(2, "0");
        } else {
          clockTime =
            minutes.toString().padStart(2, "0") +
            ":" +
            time.seconds().toString().padStart(2, "0");
        }

        setClock(clockTime);
      } else if (period.number) {
        let minutes = 0;
        let cd_mins = 40;
        if (period.number === 1) {
          if (minutes > 40 && rugby_type === "league") {
            minutes = 40;
          }
        } else if (period.number === 2) {
          minutes += 40;
          cd_mins = 80;
          if (minutes > 80 && rugby_type === "league") {
            minutes = 80;
          }
        } else if (period.number === 3) {
          minutes += 0;
          cd_mins = 5;
          if (minutes > 5 && rugby_type === "league") {
            minutes = 5;
          }
        } else if (period.number === 4) {
          minutes += 5;
          cd_mins = 5;
          if (minutes > 10 && rugby_type === "league") {
            minutes = 10;
          }
        }

        setMinutes(minutes);
        if (countDown) {
          clockTime = (cd_mins - minutes).toString().padStart(2, "0") + ":00";
        } else {
          clockTime = minutes.toString().padStart(2, "0") + ":00";
        }

        setClock(clockTime);
      }
      if (period) {
        //stop sending on load
        updateGraphic("CLOCK", {
          clock: clockTime,
          game: game,
          period: period,
          clockType,
        });
      }
    },
    [game, updateGraphic, countDown]
  );

  useEffect(() => {
    if (game) {
      let period = [...(game?.live_data?.periods || [])].sort(
        (a, b) => b.number - a.number
      )[0];

      if (period) {
        updateClock(period.kick_off, period);
      }
      let interval = setInterval(() => {
        if (period) {
          updateClock(period.kick_off, period);
        }
      }, 500);
      return () => {
        clearInterval(interval);
      };
    } else {
      updateClock(0);
    }
  }, [game, updateClock]);

  function getHalfText(number) {
    if (number === 1) {
      return "1st Half";
    } else if (number === 2) {
      return "2nd Half";
    } else if (number === 3) {
      return "GP 1";
    } else if (number === 4) {
      return "GP 2";
    }
    return number;
  }

  function setAddedTime(time) {
    let newData = { ...JSON.parse(JSON.stringify(game)) };
    let index = newData.live_data.periods.findIndex(
      (p) => p.number === period.number
    );
    newData.live_data.periods[index].added_time = time;
    updateGame({ id: game._id, live_data: newData.live_data });
    updateGraphic("CLOCK", {
      type: "ADDED_TIME",
      added_time: time,
    });
  }

  return (
    <Main>
      {game && period && <Half>{getHalfText(period.number)}</Half>}
      {/* {" "}
        {!game.actual_kick_off && (
          <Button
            green
            onClick={() => {
              updateGame({
                uuid: game.uuid,
                data: { actual_kick_off: moment().valueOf() },
              });
            }}
          >
            KICK OFF
          </Button>
        )} */}

      {clock && (
        <ClockContainer>
          {
            <Row>
              <ClockTypeButton
                selected={clockType === "manual"}
                onClick={() => {
                  let newData = { ...JSON.parse(JSON.stringify(game)) };

                  newData.live_data.periods = [
                    {
                      kick_off: moment()
                        .subtract({
                          minutes:
                            auto_clock?.split(":")?.[0] -
                            (period?.number === 2 ? 40 : 0),
                          seconds: auto_clock?.split(":")?.[1],
                        })
                        .valueOf(),
                      number: period.number,
                    },
                  ];

                  updateGame({ id: game._id, live_data: newData.live_data });
                  setClockType("manual");
                }}
              >
                Manual
              </ClockTypeButton>
              <ClockTypeButton
                selected={clockType === "stadium"}
                onClick={() => {
                  setClockType("stadium");
                }}
              >
                Stadium
              </ClockTypeButton>
              <ClockTypeButton
                selected={clockType === "hidden"}
                onClick={() => {
                  setClockType("hidden");
                }}
              >
                Hidden
              </ClockTypeButton>
            </Row>
          }
          {clockType === "manual" && (
            <Row>
              <PauseResumeButton
                onClick={() => {
                  pause();
                }}
              >
                <AiOutlinePause></AiOutlinePause>
              </PauseResumeButton>

              <ClockDiv
                onClick={() => {
                  if (location.pathname.indexOf("clock") > -1) {
                    history.push(location.pathname.replace("/clock", ""));
                  } else {
                    history.push("/" + game._id + "/clock");
                  }
                }}
              >
                <ClockSection mins id="manual_minutes">
                  {clock.split(":")[0]}
                </ClockSection>
                <ClockSplit>:</ClockSplit>
                <ClockSection id="manual_seconds">
                  {clock.split(":")[1]}
                </ClockSection>
              </ClockDiv>
              <PauseResumeButton
                onClick={() => {
                  resume();
                }}
              >
                <FiPlay></FiPlay>
              </PauseResumeButton>
            </Row>
          )}
          {clockType === "stadium" && (
            <Row>
              <ClockDiv
                flash={clockStatus !== 1}
                onClick={() => {
                  if (location.pathname.indexOf("clock") > -1) {
                    history.push(location.pathname.replace("/clock", ""));
                  } else {
                    history.push("/" + game._id + "/clock");
                  }
                }}
              >
                <ClockSection mins id="stadium_minutes">
                  {auto_clock?.split(":")[0]}
                </ClockSection>
                <ClockSplit>:</ClockSplit>
                <ClockSection id="stadium_seconds">
                  {auto_clock?.split(":")[1]}
                </ClockSection>
              </ClockDiv>
            </Row>
          )}
        </ClockContainer>
      )}
      {period && !period?.kick_off && (
        <Buttons>
          <ClockButton
            onClick={() => {
              let newData = { ...JSON.parse(JSON.stringify(game)) };
              newData.live_data.periods = [
                { kick_off: moment().valueOf(), number: period.number },
              ];
              updateGame({ id: game._id, live_data: newData.live_data });
              if (period.number === 1) {
                setHomeSubsMade(0);
                setAwaySubsMade(0);
              }
            }}
          >
            Kick Off
          </ClockButton>
        </Buttons>
      )}
      {period && period?.kick_off && (
        <Column>
          <Buttons>
            <ClockButton
              onClick={() => {
                animateOffGraphic("CLOCK", {});
              }}
            >
              OUT
            </ClockButton>

            <ClockButton
              onClick={() => {
                animateGraphic("CLOCK", {
                  teams: teams,
                  game: game,
                  clock: clock,
                  period: period,
                  clockType,
                });
              }}
              green
            >
              IN
            </ClockButton>
          </Buttons>

          {((period?.number === 1 &&
            (clockType === "manual"
              ? clock?.split(":")[0] >= 40
              : auto_clock?.split(":")[0] >= 40)) ||
            (period?.number === 2 &&
              (clockType === "manual"
                ? clock?.split(":")[0] >= 80
                : auto_clock?.split(":")[0] >= 80)) ||
            period?.number === 3 ||
            period?.number === 4) && (
            <Buttons>
              <ClockButton
                onClick={() => {
                  animateOffGraphic("CLOCK", {});
                  setTimeout(() => {
                    let newData = { ...JSON.parse(JSON.stringify(game)) };
                    newData.live_data.periods.push({
                      kick_off: null,
                      number: period.number + 1,
                    });
                    updateGame({ id: game._id, live_data: newData.live_data });

                    // update({
                    //   type: "update_clock",
                    //   _id: game._id,
                    //   data: {
                    //     number: period.number + 1,
                    //     kick_off: null,
                    //   },
                    // });
                  }, 2000);
                }}
              >
                End Half
              </ClockButton>
            </Buttons>
          )}
        </Column>
      )}
    </Main>
  );
}
