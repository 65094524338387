import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Sub from "./Sub";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { Button } from "../../Theme/Hyper";
import useLocalStorage from "../../Hooks/useLocalStorage";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Team = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const TeamName = styled.div`
  display: flex;
  padding: 0.5em;
  align-items: center;
`;
const AllButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  background-color: #181d25;
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  justify-content: center;
`;

const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const Remaining = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  aling-items: center;
  width: 100%;
  padding: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  box-sizing: border-box;
`;
const HIAButtons = styled.div`
  margin-left: auto;
  display: flex;
`;
const HIAButton = styled(Button)`
  min-width: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-left: 0.5em;
  background-color: ${(props) =>
    props.selected ? "#db0a41" : props.green ? "#0adb93" : ""};
  color: ${(props) => (props.green ? "#000" : "#fff")};
  :hover {
    cursor: pointer;
  }
`;
export default function SubsSky({
  game,
  homeSubs,
  awaySubs,
  previewGraphic,
  animateGraphic,
  animateOffGraphic,
  countries,
  sky,
  period,
  homeSubsMade,
  setHomeSubsMade,
  awaySubsMade,
  setAwaySubsMade,
}) {
  const { updateGame } = useContext(APIContext);

  const [selectedSub, setSelectedSub] = useState();
  const [isHIA, setIsHIA] = useState(false);
  const [isHIAReversal, setIsHIAReversal] = useState(false);
  const [selectedStat, setSelectedStat] = useState();
  let subsHome = [];
  for (let i = 1; i < 4; i++) {
    let s = homeSubs
      ?.filter((a) => a.sub_order === i)
      ?.sort((a, b) => a.order - b.order)
      ?.map((p) => {
        return {
          ...p,
          ...game.lineup?.home?.find((player) => player === p.player),
          team: game.home_team,
        };
      });
    if (s?.length > 0) {
      subsHome.push({ players: s });
    }
  }
  let subsAway = [];
  for (let i = 1; i < 4; i++) {
    let s = awaySubs
      ?.filter((a) => a.sub_order === i)
      ?.sort((a, b) => a.order - b.order)
      ?.map((p) => {
        return {
          ...p,
          team: game.away_team,
        };
      });
    if (s?.length > 0) {
      subsAway.push({ players: s });
    }
  }

  let homeBGColour =
    game.home_team?.variables?.[game?.home_team_kit + "_kit_bg"] || "";
  let awayBGColour =
    game.away_team?.variables?.[game?.away_team_kit + "_kit_bg"] || "";

  return (
    <Main>
      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") && (
        <Row>
          HIA
          <HIAButtons>
            <HIAButton
              green={isHIA && !isHIAReversal}
              onClick={() => {
                setIsHIA(true);
                setIsHIAReversal(false);
              }}
            >
              Yes
            </HIAButton>
            <HIAButton
              green={!isHIA && !isHIAReversal}
              onClick={() => {
                setIsHIA(false);
                setIsHIAReversal(false);
              }}
            >
              No
            </HIAButton>
            <HIAButton
              green={isHIAReversal}
              onClick={() => {
                setIsHIA();
                setIsHIAReversal(true);
              }}
            >
              Reversal
            </HIAButton>
          </HIAButtons>
        </Row>
      )}
      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") &&
        homeSubs &&
        homeSubs.length > 0 && (
          <Team>
            <TeamName
              style={{
                borderBottom: "2px solid " + (homeBGColour || "rgba(0,0,0,0)"),
              }}
            >
              {game?.home_team?.short_name}
              <Remaining>
                Made so far{" "}
                <Button
                  onClick={() => {
                    setHomeSubsMade(Math.max(0, homeSubsMade - 1));
                  }}
                >
                  -
                </Button>
                {homeSubsMade}
                <Button
                  onClick={() => {
                    setHomeSubsMade(
                      Math.min(
                        period?.number < 3
                          ? game?.home_interchanges_total ||
                              game?.competition?.variables
                                ?.number_of_interchanges
                          : game?.home_interchanges_total_gp ||
                              game?.competition?.variables
                                ?.number_of_interchanges_gp,
                        homeSubsMade + 1
                      )
                    );
                  }}
                >
                  +
                </Button>
              </Remaining>
            </TeamName>
            <Button
              onClick={() => {
                let newLineup = [...game?.lineup?.home];

                newLineup = newLineup.map((item) => {
                  return {
                    ...item,
                    squad: item.squad._id,
                    sub_order: null,
                  };
                });

                newLineup = {
                  home: newLineup,
                  away: game.lineup.away,
                };

                updateGame({
                  id: game._id,
                  lineup: newLineup,
                });
              }}
            >
              Cancel Subs
            </Button>
            {subsHome.map((sub, index) => {
              return (
                <>
                  <Sub
                    previewGraphic={(name, options) => {
                      options.remaining =
                        (period?.number < 3
                          ? game?.home_interchanges_total ||
                            game?.competition?.variables?.number_of_interchanges
                          : game?.home_interchanges_total_gp ||
                            game?.competition?.variables
                              ?.number_of_interchanges_gp) - homeSubsMade;
                      options.total =
                        period?.number < 3
                          ? game?.home_interchanges_total ||
                            game?.competition?.variables?.number_of_interchanges
                          : game?.home_interchanges_total_gp ||
                            game?.competition?.variables
                              ?.number_of_interchanges_gp;

                      animateGraphic(name, options);
                      setSelectedSub("home-" + index);
                    }}
                    players={sub.players}
                    countries={countries}
                    sky={sky}
                    game={game}
                    team={game.home_team}
                  ></Sub>
                  {selectedSub === "home-" + index && (
                    <GraphicButton
                      red
                      onClick={() => {
                        animateOffGraphic("Substitution");
                        setSelectedSub();
                      }}
                    >
                      ANIMATE OFF
                    </GraphicButton>
                  )}
                </>
              );
            })}
            {subsHome.length > 1 && (
              <>
                <AllButton
                  onClick={() => {
                    setSelectedSub("home-total");
                    if (subsHome.length === 2) {
                      animateGraphic("TwoSubstitution", {
                        subs: subsHome,
                        team: game.home_team,
                        game: game,
                        remaining:
                          (period?.number < 3
                            ? game?.home_interchanges_total ||
                              game?.competition?.variables
                                ?.number_of_interchanges
                            : game?.home_interchanges_total_gp ||
                              game?.competition?.variables
                                ?.number_of_interchanges_gp) - homeSubsMade,
                        total:
                          period?.number < 3
                            ? game?.home_interchanges_total ||
                              game?.competition?.variables
                                ?.number_of_interchanges
                            : game?.home_interchanges_total_gp ||
                              game?.competition?.variables
                                ?.number_of_interchanges_gp,
                      });
                    } else if (subsHome.length === 3) {
                      animateGraphic("ThreeSubstitution", {
                        subs: subsHome,
                        team: game.home_team,
                        game: game,
                        remaining:
                          (period?.number < 3
                            ? game?.home_interchanges_total ||
                              game?.competition?.variables
                                ?.number_of_interchanges
                            : game?.home_interchanges_total_gp ||
                              game?.competition?.variables
                                ?.number_of_interchanges_gp) - homeSubsMade,
                        total:
                          period?.number < 3
                            ? game?.home_interchanges_total ||
                              game?.competition?.variables
                                ?.number_of_interchanges
                            : game?.home_interchanges_total_gp ||
                              game?.competition?.variables
                                ?.number_of_interchanges_gp,
                      });
                    }
                  }}
                >
                  ALL
                </AllButton>
                {selectedSub === "home-total" && (
                  <GraphicButton
                    red
                    onClick={() => {
                      animateOffGraphic("Substitution");
                      setSelectedSub();
                    }}
                  >
                    ANIMATE OFF
                  </GraphicButton>
                )}
              </>
            )}
            <Button
              green
              onClick={() => {
                let subs = [
                  ...game.lineup.home
                    .filter((p) => p.sub_order)
                    .map((n) => {
                      return { ...n };
                    }),
                ];
                if (isHIA === false && isHIAReversal === false) {
                  setHomeSubsMade(homeSubsMade + subsHome?.length);
                }

                let newLineup = [...game?.lineup?.home];

                newLineup = newLineup.map((item) => {
                  return {
                    ...item,
                    squad: item.squad._id,
                    sub_order: null,
                  };
                });

                subs.forEach((sub) => {
                  let index = newLineup.findIndex(
                    (nl) => nl.squad === sub.squad._id
                  );
                  newLineup[index].subbed = true;

                  let newOrder = subs.find(
                    (s) =>
                      s.sub_order === sub.sub_order &&
                      sub.squad._id !== s.squad._id
                  ).order;
                  newLineup[index].order = newOrder;
                  newLineup[index].sub_order = null;
                  newLineup[index].hia = isHIA;
                });

                newLineup = {
                  home: newLineup,
                  away: game.lineup.away,
                };

                updateGame({
                  id: game._id,
                  lineup: newLineup,
                });
                setIsHIA(false);
                setIsHIAReversal(false);
              }}
            >
              Apply Subs
            </Button>
          </Team>
        )}
      {awaySubs && awaySubs.length > 0 && (
        <Team>
          <TeamName
            style={{
              borderBottom: "2px solid " + (awayBGColour || "rgba(0,0,0,0)"),
            }}
          >
            {game.away_team?.short_name}
            <Remaining>
              Made so far{" "}
              <Button
                onClick={() => {
                  setAwaySubsMade(Math.max(0, awaySubsMade - 1));
                }}
              >
                -
              </Button>
              {awaySubsMade}
              <Button
                onClick={() => {
                  setAwaySubsMade(
                    Math.min(
                      period?.number < 3
                        ? game?.away_interchanges_total ||
                            game?.competition?.variables?.number_of_interchanges
                        : game?.away_interchanges_total_gp ||
                            game?.competition?.variables
                              ?.number_of_interchanges_gp,
                      awaySubsMade + 1
                    )
                  );
                }}
              >
                +
              </Button>
            </Remaining>
          </TeamName>
          <Button
            onClick={() => {
              let newLineup = [...game?.lineup?.away];

              newLineup = newLineup.map((item) => {
                return {
                  ...item,
                  squad: item.squad._id,
                  sub_order: null,
                };
              });

              newLineup = {
                home: game.lineup.home,
                away: newLineup,
              };

              updateGame({
                id: game._id,
                lineup: newLineup,
              });
            }}
          >
            Cancel Subs
          </Button>
          {subsAway.map((sub, index) => {
            return (
              <>
                <Sub
                  sky={sky}
                  previewGraphic={(name, options) => {
                    options.remaining =
                      (period?.number < 3
                        ? game?.away_interchanges_total ||
                          game?.competition?.variables?.number_of_interchanges
                        : game?.away_interchanges_total_gp ||
                          game?.competition?.variables
                            ?.number_of_interchanges_gp) - awaySubsMade;
                    options.total =
                      period?.number < 3
                        ? game?.away_interchanges_total ||
                          game?.competition?.variables?.number_of_interchanges
                        : game?.away_interchanges_total_gp ||
                          game?.competition?.variables
                            ?.number_of_interchanges_gp;
                    animateGraphic(name, options);
                    setSelectedSub("away-" + index);
                  }}
                  players={sub.players}
                  countries={countries}
                  game={game}
                  team={game.away_team}
                ></Sub>
                {selectedSub === "away-" + index && (
                  <GraphicButton
                    red
                    onClick={() => {
                      animateOffGraphic("Substitution");
                      setSelectedSub();
                    }}
                  >
                    ANIMATE OFF
                  </GraphicButton>
                )}
              </>
            );
          })}
          {subsAway.length > 1 && (
            <>
              <AllButton
                onClick={() => {
                  setSelectedSub("away-total");
                  if (subsAway.length === 2) {
                    animateGraphic("TwoSubstitution", {
                      subs: subsAway,
                      team: game.away_team,
                      game: game,
                      remaining:
                        (period?.number < 3
                          ? game?.away_interchanges_total ||
                            game?.competition?.variables?.number_of_interchanges
                          : game?.away_interchanges_total_gp ||
                            game?.competition?.variables
                              ?.number_of_interchanges_gp) - awaySubsMade,
                      total:
                        period?.number < 3
                          ? game?.away_interchanges_total ||
                            game?.competition?.variables?.number_of_interchanges
                          : game?.away_interchanges_total_gp ||
                            game?.competition?.variables
                              ?.number_of_interchanges_gp,
                    });
                  } else if (subsAway.length === 3) {
                    animateGraphic("ThreeSubstitution", {
                      subs: subsAway,
                      team: game.away_team,
                      game: game,
                      remaining:
                        (period?.number < 3
                          ? game?.away_interchanges_total ||
                            game?.competition?.variables?.number_of_interchanges
                          : game?.away_interchanges_total_gp ||
                            game?.competition?.variables
                              ?.number_of_interchanges_gp) - awaySubsMade,
                      total:
                        period?.number < 3
                          ? game?.away_interchanges_total ||
                            game?.competition?.variables?.number_of_interchanges
                          : game?.away_interchanges_total_gp ||
                            game?.competition?.variables
                              ?.number_of_interchanges_gp,
                    });
                  }
                }}
              >
                ALL
              </AllButton>
              {selectedSub === "away-total" && (
                <GraphicButton
                  red
                  onClick={() => {
                    animateOffGraphic("Substitution");
                    setSelectedSub();
                  }}
                >
                  ANIMATE OFF
                </GraphicButton>
              )}
            </>
          )}
          <Button
            green
            onClick={() => {
              let subs = [
                ...game.lineup.away
                  .filter((p) => p.sub_order)
                  .map((n) => {
                    return { ...n };
                  }),
              ];
              if (isHIA === false && isHIAReversal === false) {
                setAwaySubsMade(Math.max(awaySubsMade + subsAway?.length));
              }
              let newLineup = [...game?.lineup?.away];

              newLineup = newLineup.map((item) => {
                return {
                  ...item,
                  squad: item.squad._id,
                  sub_order: null,
                };
              });

              subs.forEach((sub) => {
                let index = newLineup.findIndex(
                  (nl) => nl.squad === sub.squad._id
                );
                newLineup[index].subbed = true;

                let newOrder = subs.find(
                  (s) =>
                    s.sub_order === sub.sub_order &&
                    sub.squad._id !== s.squad._id
                ).order;
                newLineup[index].order = newOrder;
                newLineup[index].sub_order = null;
                newLineup[index].hia = isHIA;
              });

              newLineup = {
                home: game.lineup.home,
                away: newLineup,
              };

              updateGame({
                id: game._id,
                lineup: newLineup,
              });
              setIsHIA(false);
              setIsHIAReversal(false);
            }}
          >
            Apply Subs
          </Button>
        </Team>
      )}
      <GraphicButton
        style={{ width: "90%" }}
        onClick={() => {
          setSelectedStat("Interchanges made");
          previewGraphic("TeamStat", {
            stat: {
              home:
                homeSubsMade +
                "/" +
                (period?.number < 3
                  ? game?.home_interchanges_total ||
                    game?.competition?.variables?.number_of_interchanges
                  : game?.home_interchanges_total_gp ||
                    game?.competition?.variables?.number_of_interchanges_gp),
              away:
                awaySubsMade +
                "/" +
                (period?.number < 3
                  ? game?.away_interchanges_total ||
                    game?.competition?.variables?.number_of_interchanges
                  : game?.away_interchanges_total_gp ||
                    game?.competition?.variables?.number_of_interchanges_gp),
              category: "Interchanges made",
            },
            game,
          });
        }}
      >
        Interchanges made
        <br />
        {game.home_team?.code}:{" "}
        {homeSubsMade +
          "/" +
          (period?.number < 3
            ? game?.home_interchanges_total ||
              game?.competition?.variables?.number_of_interchanges
            : game?.home_interchanges_total_gp ||
              game?.competition?.variables?.number_of_interchanges_gp)}{" "}
        - {game.away_team?.code}:{" "}
        {awaySubsMade +
          "/" +
          (period?.number < 3
            ? game?.away_interchanges_total ||
              game?.competition?.variables?.number_of_interchanges
            : game?.away_interchanges_total_gp ||
              game?.competition?.variables?.number_of_interchanges_gp)}
      </GraphicButton>
      {selectedStat === "Interchanges made" && (
        <GraphicButton
          style={{ width: "90%" }}
          red
          onClick={() => {
            setSelectedStat();
            animateOffGraphic("TeamStat");
          }}
        >
          Animate off
        </GraphicButton>
      )}
    </Main>
  );
}
