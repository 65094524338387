import React from "react";
import styled from "styled-components";
import { ClockContext } from "../../contexts/ClockContext";
import { useContext } from "react";
import { ScrollBar } from "../../Theme/Hyper";

const Button = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #181d25;
  min-height: 50px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Title = styled.div`
  display: flex;
  justify-content: cetner;
`;
const Caption = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  text-align: center;
`;
const GraphicsList = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  max-height: 560px;
  overflow-y: auto;
  width: 100%;
  align-items: center;
`;

const SplitGraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 45%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
export default function Player({ player, previewGraphic, game, period }) {
  const { clockType } = useContext(ClockContext);

  return (
    <GraphicsList>
      <Button
        onClick={() => {
          previewGraphic("Name", {
            player: player,
            subtext: player?.team?.name,

            team: player?.team,
            game,
            show_number: true,
          });
        }}
      >
        Name
      </Button>
      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") && (
        <Button
          onClick={() => {
            previewGraphic("Name", {
              player: player,
              subtext: player?.team?.name,

              team: player?.team,
              game,
              show_number: false,
            });
          }}
        >
          Name - No number
        </Button>
      )}
      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") && (
        <Button
          onClick={() => {
            previewGraphic("NameTag", {
              player: player,
              subtext: player?.team?.name,

              team: player?.team,
              game,
              show_number: false,
            });
          }}
        >
          Name - Tag
        </Button>
      )}
      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("POTM", {
              player: player,
              subtext:
                game?.competition?.variables?.player_of_the_match_text ||
                "Betfred Player of the Match",
              team: player?.team,
              game,
              show_number:
                window.ENV?.REACT_APP_SKY !== "true" &&
                process.env.REACT_APP_SKY !== "true"
                  ? false
                  : true,
            });
          }}
        >
          Player of the Match
        </Button>
      )}

      {window.ENV?.REACT_APP_SKY !== "true" &&
        process.env.REACT_APP_SKY !== "true" &&
        !player.title && (
          <Button
            onClick={() => {
              previewGraphic("Card", {
                player,
                cardType: 1,
                team: player?.team,
                game,
              });
            }}
          >
            Yellow Card
          </Button>
        )}

      {window.ENV?.REACT_APP_SKY !== "true" &&
        process.env.REACT_APP_SKY !== "true" &&
        !player.title && (
          <Button
            onClick={() => {
              previewGraphic("Card", {
                player,
                cardType: 1,
                team: player?.team,
                game,
                u3: true,
              });
            }}
          >
            U3 Yellow Card
          </Button>
        )}

      {window.ENV?.REACT_APP_SKY !== "true" &&
        process.env.REACT_APP_SKY !== "true" &&
        !player.title && (
          <Button
            onClick={() => {
              previewGraphic("Card", {
                player,
                cardType: 3,
                team: player?.team,
                game,
              });
            }}
          >
            Red Card
          </Button>
        )}
      {window.ENV?.REACT_APP_SKY !== "true" &&
        process.env.REACT_APP_SKY !== "true" &&
        !player.title && (
          <Button
            onClick={() => {
              previewGraphic("Card", {
                player,
                cardType: 3,
                team: player?.team,
                game,
                u3: true,
              });
            }}
          >
            U3 Red Card
          </Button>
        )}
      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") && (
        <Button
          onClick={() => {
            previewGraphic("LTSINBIN", {
              player,
              cardType: 1,
              team: player?.team,
              game,
              show_number:
                window.ENV?.REACT_APP_SKY !== "true" &&
                process.env.REACT_APP_SKY !== "true"
                  ? false
                  : true,
            });
          }}
        >
          Sin bin
        </Button>
      )}
      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") && (
        <Button
          onClick={() => {
            previewGraphic("LTSINBIN", {
              player,
              cardType: 1,
              team: player?.team,
              game,
              countdown: true,
              clockType,
              show_number:
                window.ENV?.REACT_APP_SKY !== "true" &&
                process.env.REACT_APP_SKY !== "true"
                  ? false
                  : true,
            });
          }}
        >
          Sin bin Countdown
        </Button>
      )}
      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") && (
        <Button
          onClick={() => {
            previewGraphic("LTREDCARD", {
              player,
              cardType: 1,
              team: player?.team,
              game,
              show_number:
                window.ENV?.REACT_APP_SKY !== "true" &&
                process.env.REACT_APP_SKY !== "true"
                  ? false
                  : true,
            });
          }}
        >
          Sent off
        </Button>
      )}
      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") && (
        <Button
          onClick={() => {
            previewGraphic("LTONREPORT", {
              player,
              cardType: 1,
              team: player?.team,
              game,
              show_number:
                window.ENV?.REACT_APP_SKY !== "true" &&
                process.env.REACT_APP_SKY !== "true"
                  ? false
                  : true,
            });
          }}
        >
          On report
        </Button>
      )}

      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") && (
        <Button
          onClick={() => {
            try {
              let scorers = (game?.home_scorers || [])?.concat(
                game?.away_scorers || []
              );
              let made =
                scorers?.filter((g) => {
                  return (
                    g.player &&
                    player.squad &&
                    g?.player === player?.squad?._id &&
                    (g?.type === "conversion" || g?.type === "penalty_kick")
                  );
                })?.length || 0;
              let missed =
                scorers?.filter((g) => {
                  return (
                    g.player &&
                    player.squad &&
                    g?.player === player?.squad?._id &&
                    (g?.type === "missed_conversion" ||
                      g?.type === "missed_penalty_kick")
                  );
                })?.length || 0;
              let player_stats = game?.opta_home_player_stats?.find(
                (p) => p?.player === player.squad?.player?.opta_ID
              );
              if (!player_stats || player_stats?.length === 0) {
                player_stats = game?.opta_away_player_stats?.find(
                  (p) => p?.player === player.squad?.player?.opta_ID
                );
              }
              let stats = [];
              stats.push({
                name: "Goal kicking",
                value: made + "/" + (made + missed),
              });
              if (parseInt(player_stats?.stats?.drop_goals) > 0) {
                stats.push({
                  name: "Drop goals",
                  value: player_stats?.stats?.drop_goals,
                });
              }
              if (parseInt(player_stats?.stats?.forty_twenty) > 0) {
                stats.push({
                  name: "40/20s",
                  value: player_stats?.stats?.forty_twenty,
                });
              }
              stats.push({
                name: "Kicks in general play",
                value: player_stats?.stats?.kicks_in_gen_play,
              });
              stats.push({
                name: "Attacking kicks",
                value: player_stats?.stats?.attacking_kicks,
              });
              stats.push({
                name: "Kick from hand metres",
                value: player_stats?.stats?.kick_from_hand_metres + "m",
              });
              previewGraphic("PLAYERCB", {
                player,
                game,
                team: game?.opta_home_player_stats?.find(
                  (p) => p?.player === player.squad?.player?.opta_ID
                )
                  ? game?.home_team
                  : game?.away_team,
                subline: "Kicking profile",
                stats,
              });
            } catch (err) {}
          }}
        >
          Kicking profile
        </Button>
      )}

      {player?.type !== "coach" &&
        player?.squad?.stories &&
        player?.squad?.stories.map((stat) => {
          return (
            <Button
              onClick={() => {
                previewGraphic("Name", {
                  team: player?.team,
                  player: player,
                  subtext: stat.story,
                  line_1: stat?.line_1,
                  line_2: stat?.line_2,
                  shirt_number: player.shirt_number || "",
                  game,
                  show_number:
                    window.ENV?.REACT_APP_SKY !== "true" &&
                    process.env.REACT_APP_SKY !== "true"
                      ? false
                      : true,
                });
              }}
            >
              <Caption>{stat.story}</Caption>
            </Button>
          );
        })}
    </GraphicsList>
  );
}
